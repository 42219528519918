import { CardProps, Tooltip, useTheme } from "@mui/material";
import { FC } from "react";

import { OverviewTableCell } from "../overview-table-cell/overview-table-cell";
import { OverviewTableCellBody } from "../overview-table-cell/overview-table-cell-body";
import { OverviewTableCellHeader } from "../overview-table-cell/overview-table-cell-header";
import { ProgressBar } from "../progress-bar/progress-bar";

export interface ProgressBarCellProps {
  /** Cell header */
  header: string;
  /** progress value */
  progress: number;
  /** progress bar color */
  progressColor: string;
  /** Size of the header text. Options: "normal" (default) or "small" */
  headerTextSize?: "normal" | "small";
  /** Additional MUI styling */
  cardProps?: CardProps;
  /** Show tooltip */
  tooltipShow?: boolean;
  /** Tooltip title */
  tooltipTitle?: string;
}

/** cell that cotains the issues progress for the project */
export const ProgressBarCell: FC<ProgressBarCellProps> = ({
  header,
  progress,
  progressColor,
  cardProps,
  headerTextSize = "normal",
  tooltipShow = false,
  tooltipTitle,
}) => {
  const theme = useTheme();
  return (
    <>
      {tooltipShow ? (
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          disableInteractive
          followCursor
          title={tooltipTitle || ""}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "white",
                "& .MuiTooltip-arrow": {
                  color: "white",
                },
                color: theme.palette.text.primary,
                p: 2,
                fontWeight: 400,
                fontSize: "1rem",
                backgroundColor: theme.palette.background.paper,
                padding: 1,
                borderRadius: "6px",
                border: "1px solid",
                borderColor: theme.palette.secondary.dark,
                whiteSpace: "pre-wrap",
              },
            },
          }}
        >
          <OverviewTableCell {...cardProps}>
            <OverviewTableCellHeader
              sx={
                headerTextSize === "small"
                  ? {
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "18px",
                    }
                  : {}
              }
            >
              {header}
            </OverviewTableCellHeader>
            <OverviewTableCellBody sx={{ pt: 1, minWidth: "85px" }}>
              <ProgressBar
                progress={progress}
                progressColor={progressColor}
                progressBarHeight="12px"
                backgroundColor="#EEEEEE"
              />
            </OverviewTableCellBody>
          </OverviewTableCell>
        </Tooltip>
      ) : (
        <OverviewTableCell {...cardProps}>
          <OverviewTableCellHeader
            sx={
              headerTextSize === "small"
                ? {
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "18px",
                  }
                : {}
            }
          >
            {header}
          </OverviewTableCellHeader>
          <OverviewTableCellBody sx={{ pt: 1, minWidth: "85px" }}>
            <ProgressBar
              progress={progress}
              progressColor={progressColor}
              progressBarHeight="12px"
              backgroundColor="#EEEEEE"
            />
          </OverviewTableCellBody>
        </OverviewTableCell>
      )}
    </>
  );
};

export default ProgressBarCell;
