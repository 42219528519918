import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputProps,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import React, { FC, useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";

interface DateFormFieldProps {
  /** form element name */
  name: string;
  /** optional label text */
  label?: string;
  /** options for an optional 'ongoing' checkbox */
  ongoing?: {
    /** Ongoing checkbox label */
    label: string;
    /** ongoing checkbox value name */
    valueName: string;
  };
  /** on change event listener */
  onValueChange?: (value: Dayjs | null) => void;
  styleProps?: InputProps;
  isDisabled?: boolean;
}

/** date picker */
export const DateFormField: FC<DateFormFieldProps> = ({
  name,
  label,
  ongoing,
  onValueChange,
  styleProps = {},
  isDisabled,
}) => {
  const { control, setValue, watch } = useFormContext();

  const value = ongoing ? !!watch(ongoing.valueName) : false;
  const [disabled, setDisabled] = React.useState(value);

  const handleChange = useCallback(
    (event: {
      target: { checked: boolean | ((prevState: boolean) => boolean) };
    }) => {
      if (!ongoing) return;
      const checkedCheckbox = event.target.checked;
      setDisabled(checkedCheckbox);
      setValue(ongoing.valueName, checkedCheckbox);
    },
    [ongoing, setValue]
  );
  return (
    <div data-testid="DateFormField">
      <FormControl sx={{ height: "100%" }}>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, ...field } }) => (
            <>
              {ongoing && (
                <FormControlLabel
                  control={<Checkbox checked={value} onChange={handleChange} />}
                  label={ongoing.label}
                />
              )}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disabled={isDisabled ?? disabled}
                  InputProps={{
                    style: {
                      height: "100%",
                      borderRadius: "5px",
                      ...styleProps.style,
                    },
                  }}
                  label={label}
                  {...field}
                  onChange={(date: Dayjs | null) => {
                    onChange(date);
                    onValueChange?.(date);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </>
          )}
        />
      </FormControl>
    </div>
  );
};
